import SweetAlert from "sweetalert2";
import { ApolloError } from "@apollo/client";

export class Auth {
    static isAuthenticated() {
        const validTill = sessionStorage.getItem("admin.validTill");
        if (validTill) {
            //CASE: A token exists
            if (Date.now() > parseInt(validTill)) {
                //CASE: Token expired
                return false;
            } else {
                //CASE: Token is valid
                return true;
            }
        } else {
            return false;
        }
    }
}

export class Errors {
    static gql(error: ApolloError) {
        if (error.graphQLErrors.length > 0) {
            //CASE: A GraphQL error is present
            SweetAlert.fire({
                icon: "warning",
                title: error.graphQLErrors[0].extensions.title as string ?? "",
                text: error.graphQLErrors[0].extensions.description as string ?? ""
            });
        } else if (error.networkError) {
            if ("result" in error.networkError) {
                //CASE: error is of type ServerError
                SweetAlert.fire({
                    icon: "warning",
                    title: error.message,
                    //@ts-ignore
                    text: error.networkError.result.errors[0].message as string
                });
            } else if ("bodyText" in error.networkError) {
                //CASE: error is of type ServerParseError
                SweetAlert.fire({
                    icon: "warning",
                    title: error.message,
                    text: error.networkError.bodyText as string
                });
            } else {
                SweetAlert.fire({
                    icon: "warning",
                    title: error.message,
                    text: "Please check your network connection"
                });
            }
        } else {
            SweetAlert.fire({
                icon: "warning",
                title: error.message,
                text: "Unknown error"
            });
        }
    }
}