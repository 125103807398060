import { gql, useQuery } from "@apollo/client";
import { Table, Tag, theme } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminMainUi from "../components/AdminMainUi";
import * as GQL from "../lib/interface_gql";
import { Auth, Errors } from "../lib/util";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import AdminPackageDrawer from "../components/AdminPackageDrawer";

export default function AdminPackages() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/admins/@unknown/login"); });
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { loading, error, data, fetchMore, refetch } = useQuery<Pick<GQL.Query, "GetPackages">, {}>(gql`
        query GetPackages($skip: Int! = 0) {
            GetPackages(skip: $skip) { id isActive name period cost tax }
        }
    `);
    const [selectedItem, setSelectedItem] = useState<GQL.Package | null>(null);

    if (error) Errors.gql(error);

    const columns: ColumnsType<GQL.Package> = [
        {
            title: "State",
            render: (_text, record) => record.isActive ? <Tag color="success">Active</Tag> : <Tag color="error">Deactivated</Tag>,
            key: "state"
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Period",
            render: (_text, record) => Math.trunc(record.period / (1000 * 60 *60 *24)) + " days",
            key: "period",
        },
        {
            title: "Price",
            render: (_text, record) => `Rs. ${record.cost}`,
            key: "cost",
        },
        {
            title: "Tax",
            dataIndex: "tax",
            key: "tax",
        }
    ];

    return <AdminMainUi selectedKey="/admins/packages">
        <AdminPackageDrawer
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            refetch={refetch}
        />

        <Content style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data?.GetPackages}
                scroll={{y: 400}}
                pagination={{
                    pageSize: 9,
                    onChange: (page) => {
                        fetchMore({
                            variables: { skip: (page - 1) * 10 }
                        })
                    }
                }} onRow={(record) => {
                    return {
                        onClick: () => { setSelectedItem(record) }
                    };
                }}
            />
        </Content>
    </AdminMainUi>;
}