import * as Icon from "@ant-design/icons";
import * as GQL from "../lib/interface_gql";
import { gql, useMutation } from "@apollo/client";
import { Button, Layout, Menu, MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Errors } from "../lib/util";

type MenuItem = Required<MenuProps>["items"][number];

function createMenuItem(
    label: React.ReactNode,
    key: React.Key,
    icon: React.ReactNode
): MenuItem {
    return { key, icon, label } as MenuItem;
}

export default function AdminMainUi(props: {
    selectedKey: string
    children: ReactNode
}) {
    const navigate = useNavigate();
    const [signOut] = useMutation<Pick<GQL.Mutation, "SignOut">, {}>(gql`
        mutation SignOut {
            SignOut
        }
    `);

    return <Layout style={{ height: "100vh" }}>
        <Header style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 24px" }}>
            <img src="/dist/images/ads-connect-logo.png" height={"70%"} alt="Logo" />
            <Menu selectedKeys={[props.selectedKey]} theme="dark" mode="horizontal" style={{
                    width: "80%",
                    flexGrow: 1
                }} onClick={({ key }) => { navigate(key) }} items={[
                    createMenuItem("Dashboard", "/admins/dashboard", <Icon.DashboardOutlined />),
                    createMenuItem("Users", "/admins/users", <Icon.UserOutlined />),
                    createMenuItem("Packages", "/admins/packages", <Icon.AppstoreOutlined />),
                    createMenuItem("Temporary Numbers", "/admins/virtualNumbers", <Icon.PhoneOutlined />),
                    createMenuItem("Purchases", "/admins/purchases", <Icon.DollarOutlined />)
                ]}
            />
            <Button icon={<Icon.LogoutOutlined />} type="link" onClick={() => {
                signOut().then(res => {
                    if (res.data?.SignOut) {
                        sessionStorage.removeItem("admin.validTill");
                        sessionStorage.removeItem("admin.accessToken");
                        navigate("/admins/@unknown/login");
                    }
                }).catch(error => Errors.gql(error))
            }}>Logout</Button>
        </Header>
        <Layout style={{ padding: 24 }}>
            {props.children}
        </Layout>
    </Layout>
}