import * as GQL from "../lib/interface_gql";
import UserMainUi from "../components/UserMainUi";
import { HistoryOutlined } from "@ant-design/icons";
import { Table, Tag, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import { gql, useQuery } from "@apollo/client";
import { ColumnsType } from "antd/es/table";

const columns: ColumnsType<GQL.Purchase> = [
    {
        title: "State",
        key: "state",
        render: (_text, record) => {
            return record.endsAt < Date.now() ? <Tag color="default">Expired</Tag> :
            record.state === GQL.PurchaseState.RESERVED ? <Tag color="processing">Pending Payment</Tag> :
            record.state === GQL.PurchaseState.INITIATED ?
                record.paymentMethod === GQL.PaymentMethod.CARD ? 
                <Tag color="processing">Pending Transaction</Tag> :
                <Tag color="processing">Reviewing Payment</Tag> :
            record.state === GQL.PurchaseState.MODIFYING ? <Tag color="processing">Processing</Tag> :
            record.state === GQL.PurchaseState.ACTIVE ? <Tag color="success">Active</Tag> :
            record.state === GQL.PurchaseState.SUSPENDED ? <Tag color="warning">Suspended</Tag> :
            <Tag color="error">Error</Tag>;
        },
    },
    {
        title: "Payment Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        responsive: ["lg"]
    },
    {
        title: "Package",
        render: (_text, record) => record.package.name,
        key: "package.name",
        responsive: ["md", "lg"]
    },
    {
        title: "Temporary number",
        render: (_text, record) => record.virtualNumber.number,
        key: "virtualNumber.number"
    },
    {
        title: "Activated at",
        render: (_text, record) => new Date(record.initAt).toLocaleString(),
        key: "initAt",
        responsive: ["lg"]
    },
    {
        title: "Valid till",
        render: (_text, record) => record.state === GQL.PurchaseState.ACTIVE || record.state === GQL.PurchaseState.SUSPENDED ? new Date(record.endsAt).toLocaleString() : <HistoryOutlined />,
        key: "endsAt",
        responsive: ["lg"]
    },
    {
        title: "Active period",
        render: (_text, record) => `${Math.trunc((record.endsAt - record.initAt) / 1000 / 3600 / 24)} days`,
        key: "package.period",
        responsive: ["lg"]
    },
    {
        title: "Payment",
        render: (_text, record) => `Rs. ${record.payment.toFixed(2)}`,
        key: "package.cost",
        responsive: ["lg"]
    }
];

export default function UserPurchases() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/@unknown/login"); });
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { loading, error, data, fetchMore } = useQuery<Pick<GQL.Query, "GetMe">, {}>(gql`
        query GetMe($skip: Int! = 0) {
            GetMe {
                purchases(skip: $skip) {
                    id state initAt endsAt payment paymentMethod
                    package { name }
                    virtualNumber { number }
                }
            }
        }
    `);

    if (error) Errors.gql(error);

    return <UserMainUi selectedKey="/@me/purchases">
        <Content style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}>
            <Table loading={loading} columns={columns} dataSource={data?.GetMe.purchases} scroll={{ y: 400 }}
                rowClassName={(record) => record.endsAt < Date.now() ? "disabled-row" : ""}
                pagination={{
                    pageSize: 9,
                    onChange: (page) => {
                        fetchMore({
                            variables: { skip: (page - 1) * 10 }
                        })
                    }
                }}
            />
        </Content>
    </UserMainUi>;
}