import * as Config from "../../config";
import * as GQL from "../lib/interface_gql";
import SweetAlert from "sweetalert2";
import { UploadOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { Alert, Button, Descriptions, Modal, Typography, Upload } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Errors } from "../lib/util";

export default function UploadReceipt({
    purchase,
    open,
    setOpen,
    refetch
}: {
    purchase: GQL.Purchase
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
    refetch: () => Promise<any>
}) {
    const [initPaymentByReceipt, { loading: ld0, error: err0 }] = useMutation<Pick<GQL.Mutation, "InitPaymentByReceipt">>(gql`
        mutation InitPaymentByReceipt {
            InitPaymentByReceipt
        }
    `);
    const [initPaymentByCard, { loading: ld1, error: err1 }] = useMutation<Pick<GQL.Mutation, "InitPaymentByCard">>(gql`
        mutation InitPaymentByCard {
            InitPaymentByCard { url }
        }
    `);
    const [hasUpload, setHasUpload] = useState(false);

    if (err0) Errors.gql(err0);
    else if (err1) Errors.gql(err1);

    return <Modal title="Payment Instructions" centered open={open} onCancel={() => {
        setHasUpload(false);
        setOpen(false);
    }} footer={null}>
        {purchase.paymentMethod === GQL.PaymentMethod.DEPOSIT && <>
            <Typography.Title level={5}>2. Do your payment as follows</Typography.Title>
            <Descriptions bordered size="small" style={{ marginBlock: 16 }} items={[
                { key: "1", label: "Amount", children: `Rs. ${(purchase.payment + purchase.paymentTax).toFixed(2)}`, span: 3 },
                { key: "2", label: "Name", children: "Adeona Technologies (Pvt) Ltd.", span: 3 },
                { key: "3", label: "Bank", children: "Commercial Bank", span: 3 },
                { key: "4", label: "Branch", children: "Seeduwa", span: 3 },
                { key: "5", label: "A/C No", children: "1640044610", span: 3 },
                { key: "6", label: "Reference", children: purchase.virtualNumber.number, span: 3 },
            ]} />
        </>}

        {purchase.paymentMethod === GQL.PaymentMethod.LANKA_QR && <>
            <Typography.Title level={5}>2. Scan the following QR with any LankaQR supported app</Typography.Title>
            <img src="/dist/images/lankaqr.jpeg" style={{ height: 300 }} alt="LankaQR" />
        </>}

        {purchase.paymentMethod === GQL.PaymentMethod.CARD ? <>
            <Typography.Title level={5}>2. Complete your transaction</Typography.Title>
            <Button type="primary" loading={ld1} icon={<ShoppingCartOutlined />} onClick={() => {
                initPaymentByCard().then(res => {
                    if (res.data?.InitPaymentByCard) {
                        // eslint-disable-next-line no-restricted-globals
                        location.href = res.data.InitPaymentByCard.url;
                    }
                }).catch(error => Errors.gql(error));
            }}>Pay Now</Button>
        </> : <>
            <Typography.Title level={5}>3. Upload your payment receipt</Typography.Title>
            <Alert message={<ul>
                <li>Ony .png, .jpg, .jpeg, and .pdf are accepted</li>
                <li>Please keep your file under 5 MB</li>
            </ul>} type="info" style={{ marginBlock: 24 }} />

            <Upload name="receipt" accept=".png,.jpg,.jpeg,.pdf" listType="picture" action={`${Config.URL_API_REST}/purchases/receipt`} maxCount={1} onRemove={() => setHasUpload(false)} headers={{ "Authorization": sessionStorage.getItem("user.accessToken")! }} beforeUpload={(file: RcFile) => {
                const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
                if (!isJpgOrPng) SweetAlert.fire({
                    icon: "warning",
                    title: "Wrong file type",
                    text: "Only PNG, JPEG, JPG or PDF files are accepted"
                });

                const isProperSize = file.size <= 5120000 //5 MB;
                if (!isProperSize) SweetAlert.fire({
                    icon: "warning",
                    title: "File size too large",
                    text: "Please keep the file size under 5 MB"
                });

                const doUpload = isJpgOrPng && isProperSize;
                if (doUpload) {
                    setHasUpload(true);
                }

                return doUpload || Upload.LIST_IGNORE;
            }}>{!hasUpload && <Button type="primary" icon={<UploadOutlined />}>Upload</Button>}</Upload>

            {hasUpload && <>
                <Typography.Title level={5}>4. Confirm Your upload and make the purchase</Typography.Title>
                <Button icon={<ShoppingCartOutlined />} type="primary" loading={ld0} style={{ marginTop: 16 }} onClick={() => {
                    initPaymentByReceipt().then(() => {
                        refetch();
                        setOpen(false);
                    }).catch(error => Errors.gql(error));
                }}>Purchase</Button>
            </>}
        </>}
    </Modal>;
}