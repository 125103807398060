import { useTranslation } from "react-i18next";

export default function App() {
  const { t, i18n } = useTranslation();

  gtag('event', 'screen_view', {
    'app_name': 'AdsConnect',
    'screen_name': 'Landing'
  });

  return (
    <div className="is-boxed has-animations">
      <header
        className="site-header"
        style={{ position: "sticky", top: 0, background: "#182538" }}
      >
        <div className="container">
          <div className="site-header-inner" style={{ gap: "16px" }}>
            <div className="brand header-brand">
              <h1 className="m-0">
                <a href="#">
                  <img
                    style={{ width: "50px" }}
                    className="header-logo-image asset-light"
                    src="dist/images/ads-connect-logo.png"
                    alt="Logo"
                  />
                  <img
                    className="header-logo-image asset-dark"
                    src="dist/images/logo-dark.svg"
                    alt="Logo"
                  />
                </a>
              </h1>
            </div>

            <div style={{ flexGrow: 1 }}></div>

            <div className="brand header-brand">
              <a href="#" onClick={() => i18n.changeLanguage(i18n.language === "en" ? "sin" : "en")}
                style={{ color: "#6B7A90" }} >
                {t("lang") === "eng" ? (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png"
                    alt="lang"
                    width={20}
                    height={20}
                  />
                ) : t("lang") === "lk" ? (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/640px-Flag_of_Sri_Lanka.svg.png"
                    alt="lang"
                    width={20}
                    height={20}
                  />
                ) : (
                  <></>
                )}
              </a>
            </div>

            <div className="brand header-brand">
              <a href="/@unknown/login" style={{ color: "#6B7A90" }}>
                {t("signIn")}
              </a>
            </div>

            <div className="brand header-brand">
              <a href="/@new" style={{ color: "#6B7A90" }}>
                {t("register")}
              </a>
            </div>
          </div>
        </div>
      </header>

      <div
        className="body-wrap boxed-container"
        style={{ maxWidth: "initial", margin: 0 }}
      >
        <main>
          <section className="hero">
            <div className="container">
              <div className="hero-inner">
                <div className="hero-copy">
                  <h1 className="hero-title mt-0">
                    Welcome to
                    <br />
                    <span style={{ color: "#535FD7" }}>Ads Connect</span>
                    <br />
                    Your Privacy, Our Priority!
                  </h1>
                  <p className="hero-paragraph">{t("t1")}</p>
                  <p>{t("t2")}</p>
                  <div className="hero-cta">
                    <a className="button button-primary" href="/@new">
                      {t("registerNow2")}
                    </a>
                    {/* <div className="lights-toggle">
                                    <input id="lights-toggle" type="checkbox" name="lights-toggle" className="switch"
                                        checked="checked">
                                    <label for="lights-toggle" className="text-xs"><span>Turn me <span
                                                className="label-text">dark</span></span></label>
                                </div> */}
                  </div>
                </div>
                <div className="hero-media">
                  <div className="header-illustration">
                    <img
                      className="header-illustration-image asset-light"
                      src="dist/images/header-illustration-light.svg"
                      alt="Header illustration"
                    />
                    <img
                      className="header-illustration-image asset-dark"
                      src="dist/images/header-illustration-dark.svg"
                      alt="Header illustration"
                    />
                  </div>
                  <div className="hero-media-illustration">
                    <img
                      className="hero-media-illustration-image asset-light"
                      src="dist/images/hero-media-illustration-light.svg"
                      alt="Hero media illustration"
                    />
                    <img
                      className="hero-media-illustration-image asset-dark"
                      src="dist/images/hero-media-illustration-dark.svg"
                      alt="Hero media illustration"
                    />
                  </div>
                  <div className="hero-media-container">
                    <img
                      className="hero-media-image asset-light"
                      width="558"
                      height="424"
                      style={{ boxShadow: "none", transform: "none" }}
                      src="https://cdn.adeonatech.net/adsconnect/spam-block.png"
                      alt="Hero media"
                    />
                    <img
                      className="hero-media-image asset-dark"
                      src="dist/images/hero-media-dark.svg"
                      alt="Hero media"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="features section">
            <div className="container">
              <div className="features-inner section-inner has-bottom-divider">
                <div className="features-header text-center">
                  <div className="container-sm">
                    <h2 className="section-title mt-0">{t("t3")}</h2>
                    <p className="section-paragraph">
                      <b>{t("t4")}:</b> {t("t5")}
                    </p>
                    <p className="section-paragraph">
                      <b>{t("t6")}:</b> {t("t7")}
                    </p>
                    <p className="section-paragraph">
                      <b>{t("t8")}:</b> {t("t9")}
                    </p>
                    <div className="features-image">
                      <img
                        className="features-illustration asset-dark"
                        src="dist/images/features-illustration-dark.svg"
                        alt="Feature illustration"
                      />
                      <img
                        className="features-box asset-dark"
                        src="dist/images/features-box-dark.svg"
                        alt="Feature box"
                      />
                      <img
                        className="features-illustration asset-dark"
                        src="dist/images/features-illustration-top-dark.svg"
                        alt="Feature illustration top"
                      />
                      <img
                        className="features-illustration asset-light"
                        src="dist/images/features-illustration-light.svg"
                        alt="Feature illustration"
                      />
                      <img
                        className="features-box asset-light"
                        width="540"
                        height="370"
                        style={{ boxShadow: "none", transform: "none" }}
                        src="https://cdn.adeonatech.net/adsconnect/manage-calls.png"
                        alt="Feature box"
                      />
                      <img
                        className="features-illustration asset-light"
                        src="dist/images/features-illustration-top-light.svg"
                        alt="Feature illustration top"
                      />
                    </div>
                  </div>
                </div>
                <div className="features-wrap">
                  <div className="feature">
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img
                          className="asset-light"
                          src="/dist/images/bronze.png"
                          width={48}
                          alt="Feature 01"
                        />
                        <img
                          className="asset-dark"
                          src="/dist/images/bronze.png"
                          width={48}
                          alt="Feature 01"
                        />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">{t("t10")}</h3>
                        <p className="text-sm mb-0">{t("t11")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img
                          className="asset-light"
                          src="/dist/images/silver.png"
                          width={48}
                          alt="Feature 02"
                        />
                        <img
                          className="asset-dark"
                          src="/dist/images/silver.png"
                          width={48}
                          alt="Feature 02"
                        />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">{t("t12")}</h3>
                        <p className="text-sm mb-0">{t("t13")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="feature-inner">
                      <div className="feature-icon">
                        <img
                          className="asset-light"
                          src="/dist/images/gold.png"
                          width={48}
                          alt="Feature 03"
                        />
                        <img
                          className="asset-dark"
                          src="/dist/images/gold.png"
                          width={48}
                          alt="Feature 03"
                        />
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title mt-0">{t("t14")}</h3>
                        <p className="text-sm mb-0">{t("t15")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                  <h2 className="section-title mt-0">{t("t16")}</h2>
                  <p className="section-paragraph">{t("t17")}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                  <h2 className="section-title mt-0">{t("t18")}</h2>
                  <p className="section-paragraph">
                    <b>{t("t19")}:</b> {t("t20")}
                  </p>
                  <p className="section-paragraph">
                    <b>{t("t21")}:</b> {t("t22")}
                  </p>
                  <p className="section-paragraph">
                    <b>{t("t23")}:</b> {t("t24")}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                  <h2 className="section-title mt-0">{t("t25")}</h2>
                  <p className="section-paragraph">
                    <a href="/@new">{t("signUp")}</a> {t("t26")}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="cta section">
            <div className="container-sm">
              <div className="cta-inner section-inner">
                <div className="cta-header text-center">
                  <h2 className="section-title mt-0">{t("t27")}</h2>
                  <p className="section-paragraph">
                    <a href="/@new">{t("registerNow")}</a> {t("t28")}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer className="site-footer has-top-divider">
          <div className="container">
            <div className="site-footer-inner">
              <div className="brand footer-brand">
                <a href="#">
                  <img
                    className="asset-light"
                    style={{ width: "100px" }}
                    src="dist/images/logo-light.png"
                    alt="Logo"
                  />
                  <img
                    className="asset-dark"
                    src="dist/images/logo-dark.svg"
                    alt="Logo"
                  />
                </a>
              </div>
              {/* <ul className="footer-links list-reset">
                        <li>
                            <a href="#">Contact</a>
                        </li>
                        <li>
                            <a href="#">About us</a>
                        </li>
                        <li>
                            <a href="#">FAQ's</a>
                        </li>
                        <li>
                            <a href="#">Support</a>
                        </li>
                        </ul> */}
              <ul className="footer-social-links list-reset">
                <li>
                  <a href="https://www.facebook.com/adeonatech/">
                    <span className="screen-reader-text">Facebook</span>
                    <img
                      style={{ width: "20px", filter: "invert()" }}
                      src="dist/images/facebook.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/adeona_tech">
                    <span className="screen-reader-text">Twitter</span>
                    <img
                      style={{ width: "20px", filter: "invert()" }}
                      src="dist/images/twitter.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/adeonatech/">
                    <span className="screen-reader-text">Instagram</span>
                    <img
                      style={{ width: "20px", filter: "invert()" }}
                      src="dist/images/instagram.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/adeona-technologies/">
                    <span className="screen-reader-text">LinkedIn</span>
                    <img
                      style={{ width: "20px", filter: "invert()" }}
                      src="dist/images/linkedin.svg"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
              <div className="footer-copyright">
                <p>
                  <b>Adeona Technologies</b>
                  <br />
                  <br />
                  677/B,
                  <br />
                  Yakkaduwa,
                  <br />
                  Ja - Ela,
                  <br />
                  Sri Lanka.
                  <br />
                  <br />
                  Telephone: (+94) 117 433 300
                  <br />
                  Email: info@adeonatech.com
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
