import * as GQL from "../lib/interface_gql";
import AdminMainUi from "../components/AdminMainUi";
import AdminPurchaseDrawer from "../components/AdminPurchaseDrawer";
import { HistoryOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Select, Table, Tag, theme } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";

export default function AdminPurchases() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/admins/@unknown/login"); });
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { loading, error, data, fetchMore, refetch } = useQuery<Pick<GQL.Query, "GetPurchases">, GQL.QueryGetPurchasesArgs>(gql`
        query GetPurchases($state: PurchaseState, $skip: Int! = 0) {
            GetPurchases(state: $state, skip: $skip) {
                id state initAt endsAt payment paymentMethod
                package { name cost tax }
                virtualNumber { number }
                user { mobile }
            }
        }
    `, {
        variables: { state: GQL.PurchaseState.INITIATED }
    });
    const [selectedItem, setSelectedItem] = useState<GQL.Purchase | null>(null);

    if (error) Errors.gql(error);

    const columns: ColumnsType<GQL.Purchase> = [
        {
            title: "State",
            key: "state",
            render: (_text, record) => {
                return record.endsAt < Date.now() ? <Tag color="default">Expired</Tag> :
                record.state === GQL.PurchaseState.RESERVED ? <Tag color="processing">Awaiting Payment</Tag> :
                record.state === GQL.PurchaseState.INITIATED ? <Tag color="processing">To Review</Tag> :
                record.state === GQL.PurchaseState.MODIFYING ? <Tag color="processing">Modifying...</Tag> :
                record.state === GQL.PurchaseState.ACTIVE ? <Tag color="success">Active</Tag> :
                record.state === GQL.PurchaseState.SUSPENDED ? <Tag color="warning">Suspended</Tag> :
                <Tag color="error">Error</Tag>;
            },
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
            key: "paymentMethod",
            responsive: ["lg"]
        },
        {
            title: "Id",
            key: "id",
            dataIndex: "id",
        },
        {
            title: "Package",
            render: (_text, record) => record.package.name,
            key: "package.name",
            responsive: ["md", "lg"]
        },
        {
            title: "Temporary number",
            render: (_text, record) => record.virtualNumber.number,
            key: "virtualNumber.number"
        },
        {
            title: "Purchased at",
            render: (_text, record) => new Date(record.initAt).toLocaleString(),
            key: "initAt",
            responsive: ["lg"]
        },
        {
            title: "Valid till",
            render: (_text, record) => record.state === GQL.PurchaseState.ACTIVE || record.state === GQL.PurchaseState.SUSPENDED ? new Date(record.endsAt).toLocaleString() : <HistoryOutlined />,
            key: "endsAt",
            responsive: ["lg"]
        },
        {
            title: "Active period",
            render: (_text, record) => `${Math.trunc((record.endsAt - record.initAt) / 1000 / 3600 / 24)} days`,
            key: "activePeriod",
            responsive: ["lg"]
        },
        {
            title: "Payment",
            render: (_text, record) => `Rs. ${record.payment}`,
            key: "payment",
            responsive: ["lg"]
        },
        {
            title: "Customer mobile",
            render: (_text, record) => record.user.mobile,
            key: "user.mobile"
        }
    ];

    return <AdminMainUi selectedKey="/admins/purchases">
        <Select
            defaultValue={GQL.PurchaseState.INITIATED}
            style={{ marginBottom: 24, width: 200 }}
            onChange={(value) => refetch({
                state: value as GQL.PurchaseState
            })}
            options={[
                { value: null, label: "All" },
                { value: GQL.PurchaseState.INITIATED, label: "To Review", default: true },
                { value: GQL.PurchaseState.MODIFYING, label: "Modifying..." },
                { value: GQL.PurchaseState.SUSPENDED, label: "Suspended" },
                { value: GQL.PurchaseState.ACTIVE, label: "Active" },
            ]}
        />

        <AdminPurchaseDrawer
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            refetch={refetch}
        />

        <Content style={{
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data?.GetPurchases}
                scroll={{ y: 400 }}
                rowClassName={(record) => record.state === GQL.PurchaseState.RESERVED ? "disabled-row" : ""}
                pagination={{
                    pageSize: 9,
                    onChange: (page) => {
                        fetchMore({
                            variables: { skip: (page - 1) * 10 }
                        })
                    }
                }} onRow={(record) => {
                    return {
                        onClick: () => { setSelectedItem(record) }
                    };
                }}
            />
        </Content>
    </AdminMainUi>;
}