import * as GQL from "../lib/interface_gql";
import * as Config from "../../config";
import UserMainUi from "../components/UserMainUi";
import { MehOutlined, StarOutlined, DollarOutlined, HistoryOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Card, Col, Flex, QRCode, Result, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import UploadReceipt from "../components/UploadReceipt";

export default function UserDashboard() {
  gtag('event', 'screen_view', {
    'app_name': 'AdsConnect',
    'screen_name': 'Home'
  });

  const navigate = useNavigate();
  useEffect(() => { if (!Auth.isAuthenticated()) navigate("/@unknown/login"); });

  const { error: err0, data: dt0, refetch: refetch0 } = useQuery<Pick<GQL.Query, "GetMe" | "GetMyPurchaseStats">, {}>(gql`
    query MeAndActivePurchase {
      GetMe { id account3cx { groupId customerId provisionLink hasProvisionedNumber } }
      GetMyPurchaseStats { hasPurchases activePurchase { state initAt endsAt payment paymentTax paymentMethod virtualNumber { number } } }
    }
  `);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  if (err0) Errors.gql(err0);

  return <UserMainUi selectedKey="/@me/dashboard">
    <Row justify="center" gutter={16}>
      {dt0?.GetMyPurchaseStats.activePurchase && <UploadReceipt purchase={dt0.GetMyPurchaseStats.activePurchase} open={openUploadModal} setOpen={setOpenUploadModal} refetch={refetch0} />}

      {dt0?.GetMyPurchaseStats.activePurchase && dt0.GetMyPurchaseStats.activePurchase.state !== GQL.PurchaseState.RESERVED && <Col style={{ marginBottom: 16 }} xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 7 }}>
        <Card style={{ height: "100%", width: "100%" }}>
          <Typography.Title level={4}>Assigned temporary number</Typography.Title>
          <Card.Meta title={<Typography.Title>{dt0.GetMyPurchaseStats.activePurchase.virtualNumber.number}</Typography.Title>} />
          <Typography.Title level={4}>Valid till</Typography.Title>
          <p>{dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.ACTIVE ? new Date(dt0.GetMyPurchaseStats.activePurchase.endsAt).toLocaleString() : <HistoryOutlined />}</p>
        </Card>
      </Col>}

      {dt0?.GetMyPurchaseStats.activePurchase && dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.ACTIVE && <Col style={{ marginBottom: 16 }} xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}>
        <Card style={{ height: "100%", width: "100%" }}>
          <Flex style={{ flexDirection: "column" }} gap={16}>
            <Typography.Title level={4} >Your Account is Active</Typography.Title>
            <Typography.Title level={5}>1. Download 3CX App</Typography.Title>
            <Space>
              <Button type="link" href="https://apps.apple.com/us/app/3cx/id992045982"><img alt="Get it on Google Play" width={130} src="/dist/images/appstore.webp" /></Button>
              <Button type="link" href="https://play.google.com/store/apps/details?id=com.tcx.sipphone14&pli=1"><img alt="Get it on Google Play" width={130} src="/dist/images/googleplay.webp" /></Button>
            </Space>
            <Typography.Title level={5}>2. Scan the QR code using 3CX to receive calls from your leads</Typography.Title>
            <QRCode size={350} value={Config.SYSTEM_3CX_URL + dt0.GetMe.account3cx.provisionLink} />
          </Flex>
        </Card>
      </Col>}

      {dt0?.GetMyPurchaseStats.activePurchase && dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.RESERVED && <Col style={{ marginBottom: 16 }} xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}>
        <Card style={{ height: "100%", width: "100%" }}>
          <Result icon={<DollarOutlined />} title="We're Awaiting Your Payment" style={{ textAlign: "center" }} >
            We've reserved a number for 30 minutes until you prepare your payment. Make sure to secure your purchase before the time runs out
          </Result>
          <Flex justify="center">
            <Button icon={<QuestionCircleOutlined />} type="primary" onClick={() => setOpenUploadModal(true)}>How To Pay?</Button>
          </Flex>
        </Card>
      </Col>}

      {dt0?.GetMyPurchaseStats.activePurchase && dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.INITIATED && <Col style={{ marginBottom: 16 }} xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}>
        <Card style={{ height: "100%", width: "100%" }}>
          {dt0.GetMyPurchaseStats.activePurchase.paymentMethod === GQL.PaymentMethod.CARD ? <>
            <Result icon={<HistoryOutlined />} title="Awaiting Transaction Completion" style={{ textAlign: "center" }}>Your Ads Connect package is pending transaction completion</Result>
            <Flex justify="center">
              <Button icon={<QuestionCircleOutlined />} type="primary" onClick={() => setOpenUploadModal(true)}>How To Pay?</Button>
            </Flex>
          </> : <Result icon={<HistoryOutlined />} title="Awaiting Admin Approval" style={{ textAlign: "center" }}>
            Your Ads Connect package is pending admin approval, which usually takes up to 24 hours
          </Result>}
        </Card>
      </Col>}

      {!dt0?.GetMyPurchaseStats.activePurchase && <Col style={{ marginBottom: 16 }} xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 9 }}><Card style={{ height: "100%", width: "100%" }}>
        {dt0?.GetMyPurchaseStats.hasPurchases ? <Result icon={<MehOutlined />} title="Account Deactivated" style={{ textAlign: "center" }} >
          Your Ads Connect account has been temporarily deactivated. To regain access to Ads Connect features, simply purchase a package.
        </Result> : <Result icon={<StarOutlined />} title="Get Started with Ads Connect!" style={{ textAlign: "center" }} >
          Welcome to Ads Connect. If you haven't procured a package yet, it's time to take the first step towards safeguarding your personal contact information. With Ads Connect, you can display a temporary number in your advertisements, ensuring your privacy is intact.
        </Result>}
        <Flex justify="center">
          <Button type="primary" href="/@me/packages">Take me there</Button>
        </Flex>
      </Card></Col>}
    </Row>
  </UserMainUi >
}