import * as GQL from "../lib/interface_gql";
import AdminMainUi from "../components/AdminMainUi";
import AdminVirtualNumberDrawer from "../components/AdminVirtualNumberDrawer";
import { gql, useQuery } from "@apollo/client";
import { Table, Tag, theme } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";

export default function AdminVirtualNumbers() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/admins/@unknown/login"); });

    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { loading, error, data, fetchMore, refetch } = useQuery<Pick<GQL.Query, "GetVirtualNumbers">, {}>(gql`
        query GetVirtualNumbers($skip: Int! = 0) {
            GetVirtualNumbers(skip: $skip) { isActive id number provider }
        }
    `);
    const [selectedItem, setSelectedItem] = useState<GQL.VirtualNumber | null>(null);

    if (error) Errors.gql(error);

    const columns: ColumnsType<GQL.VirtualNumber> = [
        {
            title: "State",
            key: "state",
            render: (_text, record) => record.isActive ? <Tag color="success">Active</Tag> : <Tag color="error">Deactivated</Tag>,
        },
        {
            title: "Number",
            key: "number",
            dataIndex: "number",
        },
        {
            title: "Provider",
            dataIndex: "provider",
            key: "provider",
        },
    ];

    return <AdminMainUi selectedKey="/admins/virtualNumbers">
        <AdminVirtualNumberDrawer
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            refetch={refetch}
        />

        <Content style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}>
            <Table
                loading={loading}
                columns={columns}
                dataSource={data?.GetVirtualNumbers}
                scroll={{y: 400}}
                pagination={{
                    pageSize: 9,
                    onChange: (page) => {
                        fetchMore({
                            variables: { skip: (page - 1) * 10 }
                        })
                    }
                }} onRow={(record) => {
                    return {
                        onClick: () => { setSelectedItem(record) }
                    };
                }}
            />
        </Content>
    </AdminMainUi>;
}