import * as GQL from "../lib/interface_gql";
import AdminMainUi from "../components/AdminMainUi";
import AdminUserDrawer from "../components/AdminUserDrawer";
import { gql, useQuery } from "@apollo/client";
import { Table, Tag, theme } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";

export default function AdminUsers() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/admins/@unknown/login"); });
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { loading, error, data, fetchMore, refetch } = useQuery<Pick<GQL.Query, "GetStandardUsers">, {}>(gql`
        query GetStandardUsers($skip: Int! = 0) {
            GetStandardUsers(skip: $skip) {
                id isActive registered mobile firstName lastName account3cx { groupId customerId, provisionLink }
            }
        }
    `);
    const [selectedItem, setSelectedItem] = useState<GQL.User | null>(null);

    if (error) Errors.gql(error);

    const columns: ColumnsType<GQL.User> = [
        {
            title: "State",
            key: "state",
            render: (_text, record) => record.isActive ? <Tag color="success">Active</Tag> : <Tag color="error">Deactivated</Tag>,
        },
        {
            title: "Mobile",
            key: "mobile",
            dataIndex: "mobile",
        },
        {
            title: "Name",
            key: "name",
            render: (_text, record) => record.firstName + " " + record.lastName,
        },
        {
            title: "Registered at",
            key: "registered",
            render: (_text, record) => new Date(record.registered).toLocaleString(),
        },
        {
            title: "3cx Group",
            key: "account3cx.groupId",
            render: (_text, record) => record.account3cx.groupId?.toString() ?? "(Not provisioned)",
        },
        {
            title: "3cx Customer",
            key: "account3cx.customerId",
            render: (_text, record) => record.account3cx.customerId?.toString() ?? "(Not provisioned)",
        },
        {
            title: "3cx Provision Link",
            key: "account3cx.provisionLink",
            render: (_text, record) => record.account3cx.provisionLink?.toString() ?? "(Not provisioned)",
        }
    ];

    return <AdminMainUi selectedKey="/admins/users">
        <AdminUserDrawer
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            refetch={refetch}
        />

        <Content style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}><Table loading={loading} columns={columns} dataSource={data?.GetStandardUsers} scroll={{ y: 400 }}
            pagination={{
                pageSize: 9,
                onChange: (page) => {
                    fetchMore({
                        variables: { skip: (page - 1) * 10 }
                    })
                }
            }} onRow={(record) => {
                return {
                    onClick: () => { setSelectedItem(record) }
                };
            }} />
        </Content>
    </AdminMainUi>;
}