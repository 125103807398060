import "./index.css";
import * as Config from "./config";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserDashboard from './users/pages/UserDashboard';
import UserLogin from './users/pages/UserLogin';
import UserPassword from './users/pages/UserPassword';
import UserAdd from './users/pages/UserAdd';
import UserPurchases from "./users/pages/UserPurchases";
import UserPackages from "./users/pages/UserPackages";
import AdminLogin from "./admins/pages/AdminLogin";
import AdminPurchases from "./admins/pages/AdminPurchases";
import AdminUsers from "./admins/pages/AdminUsers";
import AdminVirtualNumbers from "./admins/pages/AdminVirtualNumbers";
import AdminPackages from "./admins/pages/AdminPackages";
import AdminDashboard from "./admins/pages/AdminDashboard";
import UserFaq from "./users/pages/UserFaq";
import i18n from "i18next";

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { initReactI18next } from "react-i18next";

declare global {
  function gtag(...args: any): any
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          lang: "eng",
          signIn: "Sign In",
          register: "Register",
          signUp: "Sign Up",
          registerNow: "Register Now",
          registerNow2: "Register Now",
          t1: "Struggling with spam calls and messages from your online ads? Discover Ads Connect, where we safeguard your privacy while ensuring your ads reach the right audience.",
          t2: "Ready to Experience Privacy Like Never Before?",
          t3: "Why Ads Connect?",
          t4: "Privacy First",
          t5: "Keep your personal number hidden and secure.",
          t6: "Tailored Control",
          t7: "Manage how and when you want to be contacted, without losing out on valuable leads.",
          t8: "Flexible Temporary Numbers",
          t9: "Select a temporary number for a specific duration that best fits your advertising strategy.",
          t10: "Bronze Package",
          t11: "Secure your privacy with our Bronze Package, offering a temporary contact number for 7 days, perfect for short-term ad campaigns without exposing your personal contact.",
          t12: "Silver Package",
          t13: "Elevate your advertising strategy with the Silver Package, providing a dedicated temporary contact number for 14 days, ensuring privacy and continuity in your medium-duration campaigns.",
          t14: "Gold Package",
          t15: "Maximize your campaign's impact with the Gold Package, featuring a temporary contact number for an extended 21 days, ideal for comprehensive ad campaigns while safeguarding your personal contact information.",
          t16: "Who Benefits from Ads Connect?",
          t17: "Ideal for individuals and businesses posting ads online or in newspapers and looking to maintain privacy. Whether it's selling a car, renting property, or any other service, we've got you covered.",
          t18: "Take Control of Your Advertising Experience",
          t19: "Say Goodbye to Spam",
          t20: "Eliminate unwanted disturbances effectively.",
          t21: "Efficient Inquiry Management",
          t22: "Handle ad responses on your terms.",
          t23: "Lasting Peace of Mind",
          t24: "Avoid post-advertisement hassles",
          t25: "Don't Wait, Get Started Today!",
          t26: "here and choose the package that aligns with your advertising goals. Join our community of smart, privacy-conscious advertisers.",
          t27: "Your Privacy Matters to Us",
          t28: "for Ads Connect and transform your advertising experience. Protect your personal information while maximizing ad reach.",
        }
      },
      sin: {
        translation: {
          lang: "lk",
          signIn: "Sign In",
          register: "Register",
          signUp: "දැන්ම ලියාපදිංචි වී",
          registerNow: "දැන්ම ලියාපදිංචි වී",
          registerNow2: "ලියාපදිංචි වන්න",
          t1: "ඔබගේ පෞද්ගලික දුරකථන අංකය වෙළද දැන්වීම් වල දමා ඔබත් අපහසුතාවයට පත්වූ අයෙක් ද?",
          t2: "Ads Connect වෙතින් ඔබගේ වෙළද දැන්වීමට උපරිම ආකර්ෂණයක් හා ඔබගේ පෞද්ගලිකත්වය ආරක්ෂා කරගන්න.",
          t3: "Ads Connect කුමක් සදහාද?",
          t4: "පෞද්ගලිකත්වයට ප්‍රමුකතාවය",
          t5: "ඔබගේ පුද්ගලික අංකය සඟවා සුරක්ෂිතව තබා ගන්න.",
          t6: "ලැබෙන ඇමතුම් සදහා සිතැගි ලෙස පිළිතුරු සපයන්න",
          t7: "ඔබගේ වෙළද දැන්වීමට ලැබෙන දුරකථන ඇමතුම් මග නොහැර ඔබට පහසු ලෙස පිළිතුරු සපයන්න.",
          t8: "ඔබටම වෙන්වූ තාවකාලික අංකයක්",
          t9: "ඔබ තෝරාගන්නා පැකේජය අනුව අපි ඔබටම වෙන්වූ තාවකාලික දුරකථන අංකයක් සීමිත කාලයක් සදහා ඔබගේ වෙළද දැන්වීමෙහි පලකිරීමට ලබා දෙන්නෙමු.",
          t10: "Bronze පැකේජය",
          t11: "අපගේ Bronze පැකේජය සමඟින් ඔබේ පෞද්ගලිකත්වය සුරක්ෂිත කර, දින 7ක් සඳහා තාවකාලික දුරකථන අංකයක් ලබාගන්න, ඔබේ පුද්ගලික දුරකථන අංකය හෙළිදරව් නොකර කෙටි කාලීන දැන්වීම් ප්‍රචාරණ සඳහා වඩාත් සුදුසුයි.",
          t12: "Silver පැකේජය",
          t13: "Silver පැකේජය සමඟින් ඔබේ වෙළඳ ප්‍රචාරණය ඉහළ නංවා, දින 14ක් සඳහා කැපවූ තාවකාලික දුරකථන අංකයක් ලබා දෙමින්, ඔබේ මධ්‍ය කාලීන දැන්වීම් ප්‍රචාරණ කටයුතුවල පෞද්ගලිකත්වය සහ අඛණ්ඩතාව සහතික කරයි.",
          t14: "Gold පැකේජය",
          t15: "Gold පැකේජය සමඟින් ඔබේ වෙළද දැන්වීමේ බලපෑම උපරිම කරන්න, දින 21ක් සඳහා තාවකාලික දුරකථන අංකයක් ලබාගන්න, ඔබේ පුද්ගලික දුරකථන අංකය හෙළිදරව් නොකර අතරම දිගු කාලීන  දැන්වීම් ප්‍රචාරණ සඳහා වඩාත් සුදුසුය. ",
          t16: "Ads Connect කවුරුන් සදහාද?",
          t17: "අන්තර්ජාලය හරහා හෝ පුවත්පත්වල දැන්වීම් පළ කරන සහ පෞද්ගලිකත්වය සුරක්ෂිත ගැනීමට බලාපොරොත්තු වන පුද්ගලයන්ට සහ ව්‍යාපාර සඳහා වඩාත් සුදුසුය. එය මෝටර් රථයක් විකිණීම, දේපල කුලියට දීම හෝ වෙනත් ඕනෑම සේවාවක් සදහා බාවිතාවට ගත හැක.",
          t18: "ඔබගේ දැන්වීමට උපරිම ඵලදායිතාවයක්",
          t19: "Spam Call/ SMS වලට තිත",
          t20: "කරදරකාරී දුරකථන ඇමතුම් සහ SMS පණිවිඩ පහසුවෙන්ම නවතා දමන්න.",
          t21: "කාර්යක්ෂම දුරකථන ඇමතුම් කළමනාකරණය",
          t22: "ඔබගේ දැන්වීමට ලැබෙන දුරකථන ඇමතුම් ඔබට රිසිලෙස කළමනාකරණය කරන්න.",
          t23: "මනස නිදහස් කරගන්න",
          t24: "ප්‍රචාරණය කල් ඉකුත් වූ පසුව ඇති වන කරදරවලින් වළකින්න.",
          t25: "ඔබත් අදම Ads Connect සමග එක්වන්න!",
          t26: "ඔබගේ වෙළද දැන්වීමෙහි උපරිම ඵල නෙලාගන්න, ඔබත් අදම Ads Connect සමග එක්වන්න.",
          t27: "ඔබගේ පෞද්ගලිකත්වය අපට වැදගත් වේ",
          t28: "Ads Connect හි නවතම  වෙළඳ ප්‍රචාරණ අත්දැකීම ලබාගන්න. දැන්වීමට ලැබෙන ප්‍රතිචාර උපරිම කරන අතරතුර ඔබේ පුද්ගලික තොරතුරු ආරක්ෂා කරගන්න. ",
        }
      }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

function merge(existing: any[] = [], incoming: any[]) {
  if (existing.length > 0) {
    const merged = [...existing];

    // Add incoming items that don't exist in the existing array
    incoming.forEach(incomingItem => {
      const foundIncomingItemWithinExisting = existing.some(existingItem => existingItem.__ref === incomingItem.__ref);
      if (!foundIncomingItemWithinExisting) {
        merged.push(incomingItem);
      }
    });

    return merged;
  } else {
    return incoming;
  }
}

const userApollo = new ApolloClient({
  link: setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem("user.accessToken")
      },
    }
  }).concat(createHttpLink({
    uri: Config.URL_API_GQL_USER,
  })),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          purchases: {
            keyArgs: [],
            merge,
          },
        }
      }
    }
  }),
});

const adminApollo = new ApolloClient({
  link: setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: sessionStorage.getItem("admin.accessToken")
      },
    }
  }).concat(createHttpLink({
    uri: Config.URL_API_GQL_ADMIN,
  })),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        queryType: true,
        fields: {
          GetStandardUsers: {
            keyArgs: [],
            merge,
          },
          GetPackages: {
            keyArgs: [],
            merge,
          },
          GetVirtualNumbers: {
            keyArgs: [],
            merge,
          },
          GetPurchases: {
            keyArgs: ["state"],
            merge,
          },
        }
      }
    }
  }),
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <ApolloProvider client={userApollo}><App /></ApolloProvider>
  },

  {
    path: "/@new",
    element: <ApolloProvider client={userApollo}><UserAdd /></ApolloProvider>
  },
  {
    path: "/@unknown/login",
    element: <ApolloProvider client={userApollo}><UserLogin /></ApolloProvider>
  },
  {
    path: "/@unknown/password",
    element: <ApolloProvider client={userApollo}><UserPassword /></ApolloProvider>
  },
  {
    path: "/@me/dashboard",
    element: <ApolloProvider client={userApollo}><UserDashboard /></ApolloProvider>
  },
  {
    path: "/@me/purchases",
    element: <ApolloProvider client={userApollo}><UserPurchases /></ApolloProvider>
  },
  {
    path: "/@me/packages",
    element: <ApolloProvider client={userApollo}><UserPackages /></ApolloProvider>
  },
  {
    path: "/faq",
    element: <ApolloProvider client={userApollo}><UserFaq /></ApolloProvider>
  },

  {
    path: "/admins/@unknown/login",
    element: <ApolloProvider client={adminApollo}><AdminLogin /></ApolloProvider>
  },
  {
    path: "/admins/dashboard",
    element: <ApolloProvider client={adminApollo}><AdminDashboard /></ApolloProvider>
  },
  {
    path: "/admins/users",
    element: <ApolloProvider client={adminApollo}><AdminUsers /></ApolloProvider>
  },
  {
    path: "/admins/packages",
    element: <ApolloProvider client={adminApollo}><AdminPackages /></ApolloProvider>
  },
  {
    path: "/admins/virtualNumbers",
    element: <ApolloProvider client={adminApollo}><AdminVirtualNumbers /></ApolloProvider>
  },
  {
    path: "/admins/purchases",
    element: <ApolloProvider client={adminApollo}><AdminPurchases /></ApolloProvider>
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);