import * as GQL from "../lib/interface_gql";
import * as Icon from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import UserMainUi from "../components/UserMainUi";
import UploadReceipt from "../components/UploadReceipt";
import SweetAlert from "sweetalert2";
import { gql, useMutation, useQuery } from "@apollo/client";
import { BankFilled, CreditCardOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Flex, Image, Modal, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";

const commonDescriptions = [
  {
    key: "1",
    label: "Temporary Dedicated number",
    children: <Icon.CheckOutlined />,
    span: 3
  },
  {
    key: "2",
    label: "Incoming calls",
    children: "Free",
    span: 3
  },
  {
    key: "3",
    label: "Outgoing calls",
    children: <Icon.CloseOutlined />,
    span: 3
  },
  {
    key: "4",
    label: "SMS",
    children: <Icon.CloseOutlined />,
    span: 3
  }
];

export default function UserPackages() {
  gtag('event', 'screen_view', {
    'app_name': 'AdsConnect',
    'screen_name': 'Package'
  });

  const navigate = useNavigate();
  useEffect(() => { if (!Auth.isAuthenticated()) navigate("/@unknown/login"); });

  const { error: err0, data: dt0, refetch: refetch0 } = useQuery<Pick<GQL.Query, "GetPackages" | "GetMyPurchaseStats">, {}>(gql`
    query GetPackagesAndActivePurchase {
      GetPackages { id name period cost tax }
      GetMyPurchaseStats { activePurchase { state initAt endsAt payment paymentTax paymentMethod package { id period } virtualNumber { number } } }
    }
  `);
  const [reservePurchase, { loading: ld1 }] = useMutation<Pick<GQL.Mutation, "ReservePurchase">, GQL.MutationReservePurchaseArgs>(gql`
    mutation ReservePurchase($packageId: ID!, $paymentMethod: PaymentMethod!) {
      ReservePurchase(packageId: $packageId, paymentMethod: $paymentMethod) { id }
    }
  `);
  const [subscribeAlert, { error: err2 }] = useMutation<Pick<GQL.Mutation, "SubscribeAlert">, GQL.MutationSubscribeAlertArgs>(gql`
    mutation SubscribeAlert($onFreeNumber: Boolean!) {
      SubscribeAlert(onFreeNumber: $onFreeNumber)
    }
  `);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null);

  if (err0) Errors.gql(err0);
  if (err2) Errors.gql(err2);

  function handleReservePurchase(selectedPackageId: string, paymentMethod: GQL.PaymentMethod) {
    reservePurchase({
      variables: { packageId: selectedPackageId, paymentMethod }
    }).then(() => {
      refetch0();
      setSelectedPackageId(null);
      setOpenUploadModal(true);
    }).catch(error => {
      if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions.code === "INSUFFICIENT_VIRTUAL_NUMBERS") {
        SweetAlert.fire({
          icon: "warning",
          title: error.graphQLErrors[0].extensions.title as string ?? "",
          text: error.graphQLErrors[0].extensions.description as string ?? "",
          cancelButtonText: "Don't notify",
          confirmButtonText: "Notify me",
          showCancelButton: true,
        }).then(result => {
          subscribeAlert({ variables: { onFreeNumber: result.value ? true : false } }).catch(error => Errors.gql(error));
        });
      } else {
        Errors.gql(error);
      }
    })
  }

  return <UserMainUi selectedKey="/@me/packages">
    {dt0 && <Row gutter={16} align="middle">{dt0.GetPackages.map(pkg => {
      return <Col xs={{ span: 24, push: 0 }} md={{ span: 8, push: 0 }} lg={{ span: 6, push: 3 }}>
        <Card hoverable style={{ marginBottom: 16 }} cover={<Image preview={false} height={150} style={{ marginTop: 16, objectFit: "contain" }} src={`/dist/images/${pkg.name.toLowerCase()}.png`} />} actions={dt0.GetMyPurchaseStats.activePurchase?.package.id === pkg.id ? (dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.RESERVED || (dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.INITIATED && dt0.GetMyPurchaseStats.activePurchase.paymentMethod === GQL.PaymentMethod.CARD)) ? [<Button icon={<Icon.QuestionCircleOutlined />} type="primary" onClick={() => setOpenUploadModal(true)}>How To Pay?</Button>] : [<Button disabled>Rs. {pkg.cost + pkg.tax}</Button>] : [<Button disabled={dt0.GetMyPurchaseStats.activePurchase !== null} type="primary" loading={ld1} onClick={() => setSelectedPackageId(pkg.id)}>Rs. {pkg.cost + pkg.tax}</Button>]}
        >
          <Meta title={<Typography.Title level={3} style={{ textAlign: "center", margin: 0 }}>{pkg.name}</Typography.Title>} description={dt0.GetMyPurchaseStats.activePurchase?.package.id !== pkg.id && <Descriptions bordered size="small" items={[...commonDescriptions, {
            key: "4",
            label: "Validity period",
            children: `${Math.trunc(pkg.period / 1000 / 3600 / 24)} days`,
            span: 3
          }]} />} />

          {dt0.GetMyPurchaseStats.activePurchase?.package.id === pkg.id && <Result style={{ padding: 16 }}

            status={dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.ACTIVE ? "success" : dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.SUSPENDED ? "warning" : (dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.RESERVED || dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.INITIATED || dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.MODIFYING) ? "info" : "500"}

            title={dt0.GetMyPurchaseStats.activePurchase.virtualNumber.number}

            subTitle={dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.ACTIVE ? `Valid till ${new Date(dt0.GetMyPurchaseStats.activePurchase.endsAt).toLocaleString()}` : dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.SUSPENDED ? "Your purchase is under review" : dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.RESERVED ? "This number is reserved for you until you prepare your payment. Be quick and make it yours before the time runs out" : dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.INITIATED ? dt0.GetMyPurchaseStats.activePurchase.paymentMethod === GQL.PaymentMethod.CARD ? "We're waiting for the completion of your transaction" : "Your Ads Connect package is pending admin approval, which usually takes up to 24 hours" : dt0.GetMyPurchaseStats.activePurchase.state === GQL.PurchaseState.MODIFYING ? "Your purchase is bring currently processed by our system" : "500"}
          />}
        </Card>
      </Col>
    })}</Row>}

    {dt0?.GetMyPurchaseStats.activePurchase && <UploadReceipt purchase={dt0.GetMyPurchaseStats.activePurchase} open={openUploadModal} setOpen={setOpenUploadModal} refetch={refetch0} />}

    <Modal centered open={selectedPackageId !== null} title="Payment Instructions" footer={null} onCancel={() => setSelectedPackageId(null)}>
      <Typography.Title level={5}>1. Select your preferred payment method</Typography.Title>
      <Flex gap={16}>
        <Card hoverable style={{ width: "30%" }} onClick={() => handleReservePurchase(selectedPackageId!, GQL.PaymentMethod.DEPOSIT)}>
          <div style={{ textAlign: "center" }}><BankFilled style={{ fontSize: 70 }} /></div>
          <div style={{ textAlign: "center" }}>Bank Deposit</div>
        </Card>
        <Card hoverable style={{ width: "30%" }} onClick={() => handleReservePurchase(selectedPackageId!, GQL.PaymentMethod.CARD)}>
          <div style={{ textAlign: "center" }}><CreditCardOutlined style={{ fontSize: 70 }} /></div>
          <div style={{ textAlign: "center" }}>Credit/Debit Card</div>
        </Card>
        <Card hoverable style={{ width: "30%" }} onClick={() => handleReservePurchase(selectedPackageId!, GQL.PaymentMethod.LANKA_QR)}>
          <div style={{ textAlign: "center" }}><QrcodeOutlined style={{ fontSize: 70 }} /></div>
          <div style={{ textAlign: "center" }}>Lanka QR</div>
        </Card>
      </Flex>
    </Modal>
  </UserMainUi>
}