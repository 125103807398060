import * as GQL from "../lib/interface_gql";
import * as Icon from "@ant-design/icons";
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Alert, Button, Card, Checkbox, Flex, Form, Input, InputRef, Layout, Modal, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Errors } from "../lib/util";

export default function UserAdd() {
    gtag('event', 'screen_view', {
        'app_name': 'AdsConnect',
        'screen_name': 'Registration'
    });

    const navigate = useNavigate();
    const [generateOtp, { loading: ld0, data: dt0 }] = useMutation<Pick<GQL.Mutation, "GenerateOtp">, GQL.MutationGenerateOtpArgs>(gql`
        mutation GenerateOtp($for: OtpFor!, $mobile: String!) {
            GenerateOtp(for: $for, mobile: $mobile)
        }
    `);
    const [createUser, { loading: ld1, data: dt1 }] = useMutation<Pick<GQL.Mutation, "CreateUser">, GQL.MutationCreateUserArgs>(gql`
        mutation CreateUser($user: UserInput!) {
            CreateUser(user: $user) { accessToken validTill }
        }
    `);
    const [form] = Form.useForm<GQL.UserInput & {
        otp0: string, otp1: string, otp2: string, otp3: string, otp4: string, otp5: string
    }>();
    const inputOtpRefs = useRef([
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
    ]);
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [openTermsModal, setOpenTermsModal] = useState(false);

    useEffect(() => { if (dt1) navigate("/@me/dashboard"); });

    return <Layout style={{ height: "100vh", overflow: "auto" }}>
        <Flex style={{ height: "100%" }} justify="center" align="center">
            <Card
                bodyStyle={{ padding: "50px" }}
                style={{
                    width: "400px",
                    margin: "30px auto",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                }}
            >
                <Typography.Title
                    level={2}
                    style={{ marginBottom: "30px", textAlign: "center" }}
                >
                    Create an Account
                </Typography.Title>
                <Form form={form} onFinish={formData => {
                    createUser({
                        variables: {
                            user: {
                                mobile: formData.mobile,
                                otp: formData.otp0 + formData.otp1 + formData.otp2 + formData.otp3 + formData.otp4 + formData.otp5,
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                                password: formData.password
                            }
                        }
                    }).then(res => {
                        if (res.data) {
                            sessionStorage.setItem("user.accessToken", res.data.CreateUser.accessToken);
                            sessionStorage.setItem("user.validTill", res.data.CreateUser.validTill.toString());
                        }
                    }).catch(error => Errors.gql(error));
                }} layout="vertical">
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                            { required: true, message: "This is required" },
                            { pattern: /^[a-zA-Z0-9\s]{3,20}$/, message: "Only a-z,A-Z,0-9 is allowed. Must be between 3-20 characters" }
                        ]}
                    >
                        <Input placeholder="e.g. John" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                            { required: true, message: "This is required" },
                            { pattern: /^[a-zA-Z0-9\s]{3,20}$/, message: "Only a-z,A-Z,0-9 is allowed. Must be between 3-20 characters" }
                        ]}
                    >
                        <Input placeholder="e.g. Doe" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: "This is required" },
                            { max: 20, message: "Must not exceed 20 characters" }
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                            { required: true, message: "This is required" },
                            { pattern: /^0\d{9}$/, message: "Mobile number must be 10 digits. Starts with 0" },
                        ]}
                    >
                        <Space.Compact style={{ width: "100%" }}>
                            <Input
                                prefix={<Icon.MobileOutlined />}
                                placeholder="e.g. 077XXXXXXX"
                            />

                            <Button disabled={openOtpModal} loading={ld0} type="primary" onClick={async () => {
                                let formData;
                                try {
                                    formData = await form.validateFields();
                                } catch (error) {
                                    console.log("Form validation error", error);
                                    return;
                                }

                                await generateOtp({
                                    variables: {
                                        for: GQL.OtpFor.MOBILE_VALIDATION,
                                        mobile: formData.mobile
                                    }
                                }).then(res => {
                                    if (res.data?.GenerateOtp) {
                                        setOpenOtpModal(true);
                                        setTimeout(() => setOpenOtpModal(false), 120000);
                                    }
                                }).catch(error => Errors.gql(error));
                            }}>Request OTP</Button>
                        </Space.Compact>
                    </Form.Item>

                    {openOtpModal && <>
                        <Alert message={<p>We've sent an OTP to your mobile and will expire in 2 minutes</p>} type="success" style={{ marginBottom: 24 }} />
                        <Flex justify="space-between">{Array.from({ length: 6 }, (_, index) => (<Form.Item
                            key={index}
                            name={`otp${index}`}
                            rules={[
                                { required: true, message: "" },
                                { pattern: /\d{1}/, message: "" }
                            ]}>
                            <Input onKeyUp={() => {
                                const nextInputRef = inputOtpRefs.current[index + 1]?.current;
                                if (nextInputRef) {
                                    nextInputRef.focus()
                                    nextInputRef.input!.value = ""
                                }
                            }}
                                ref={inputOtpRefs.current[index]}

                                style={{ width: "40px", height: "40px" }}
                                maxLength={1}
                            />
                        </Form.Item>))}</Flex>
                    </>}

                    {dt0?.GenerateOtp && openOtpModal &&
                        <>
                            <Form.Item
                                name="acceptTerms"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        transform: value => (value || undefined),
                                        type: "boolean",
                                        message: "Terms are not accepted"
                                    },
                                ]}
                            >
                                <Checkbox>Accept <a onClick={() => setOpenTermsModal(true)}>terms and conditions</a></Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"

                                    loading={ld1}
                                    style={{ width: "100%" }}
                                >
                                    Register
                                </Button>
                            </Form.Item>
                        </>
                    }
                </Form>
            </Card>
        </Flex>

        <Modal open={openTermsModal} centered cancelText="Close" onOk={() => setOpenTermsModal(false)} onCancel={() => setOpenTermsModal(false)}>
            <p><b>Terms and Conditions for Ads Connect by Adeona Technologies (Private) Limited</b></p>

            <p>These Terms and Conditions ("Terms") govern your use of the "Ads Connect" service offered by Adeona Technologies (Private) Limited ("Adeona," "we," "us," or "our"). By using Ads Connect, you agree to abide by these Terms, so please read them carefully.</p>

            <p>1. No Refunds: Once you have procured an Ads Connect package, please be aware that Adeona does not offer refunds for any reason. All purchases are final.</p>

            <p>2. Connectivity Dependence: Ads Connect relies on the connectivity provided by our telecommunications partner. While we make every effort to ensure uninterrupted service, we have no control over connectivity termination initiated by the operator. Additionally, in cases where a government entity requests service termination, we are obligated to comply. In such scenarios, your service will be terminated immediately.</p>

            <p>3. Usage Restrictions: Users of Ads Connect are strictly prohibited from using the service for any purpose other than displaying the provided number in their advertisements. Any other activities or misuse of the service are strictly prohibited and may result in immediate termination of your account.</p>
            
            <p>4. Termination of Service: Adeona reserves the right to terminate or suspend your Ads Connect account and access to the service at our discretion, without prior notice, if we believe you have violated these Terms or engaged in any unauthorized or prohibited use of the service.</p>

            <p>5. Privacy and Data Protection: We take your privacy seriously. Adeona employs industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. Adeona does not share your personal information with third parties for marketing purposes. Your information is used solely for the provision of the Ads Connect service.</p>

            <p>6. Changes to Terms and Service: Adeona may update or modify these Terms or the Ads Connect service from time to time. Any changes will be posted on our website, and it is your responsibility to review them periodically. Continued use of the service after such changes constitutes your acceptance of the revised Terms.</p>

            <p>7. Disclaimer of Warranty: Ads Connect is provided "as is" and "as available." Adeona does not make any warranties, express or implied, regarding the service, including its accuracy, reliability, or fitness for a particular purpose.</p>

            <p>8. Limitation of Liability: To the extent permitted by law, Adeona shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of Ads Connect.</p>

            <p>9. Governing Law and Jurisdiction: These Terms are governed by the laws of Sri Lanka. Any disputes arising out of or related to these Terms or your use of Ads Connect shall be subject to the exclusive jurisdiction of the courts in Sri Lanka.</p>

            <p>10. Contact Us: If you have any questions or concerns regarding these Terms or Ads Connect, please contact our support team at <a href="mailto:support@adeonatech.net">support@adeonatech.net</a>.</p>
        </Modal>
    </Layout>;
};
