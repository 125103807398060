import * as GQL from "../lib/interface_gql";
import AdminMainUi from "../components/AdminMainUi";
import { Card, Col, Row, Typography, theme } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Errors } from "../lib/util";
import { Content } from "antd/es/layout/layout";
import { gql, useQuery } from "@apollo/client";

export default function AdminDashboard() {
    const navigate = useNavigate();
    useEffect(() => { if (!Auth.isAuthenticated()) navigate("/admins/@unknown/login"); });

    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();
    const { error, data } = useQuery<Pick<GQL.Query, "GetStatistics">, {}>(gql`
        query GetStatistics {
            GetStatistics { allVirtualNumbers freeVirtualNumbers { id number } }
        }
    `);

    if (error) Errors.gql(error);

    return <AdminMainUi selectedKey="/admins/dashboard">
        <Content style={{
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}>
            <Typography.Title level = {2}>Temporary Numbers</Typography.Title>
            {data && <>
                <Row gutter={16} style={{marginBottom: 16}}>
                    <Col span={6}>
                        <Card hoverable>
                            <Card.Meta title={<Typography.Title level={3} style={{ margin: 0 }}>{data.GetStatistics.freeVirtualNumbers.length} / {data.GetStatistics.allVirtualNumbers}</Typography.Title>} description="Free numbers" />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card hoverable>
                            <Card.Meta title={<Typography.Title level={3} style={{ margin: 0 }}>{data.GetStatistics.allVirtualNumbers - data.GetStatistics.freeVirtualNumbers.length} / {data.GetStatistics.allVirtualNumbers}</Typography.Title>} description="Provisioned numbers" />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card hoverable>
                            <Card.Meta title={<Typography.Title level={3} style={{ margin: 0 }}>{data.GetStatistics.freeVirtualNumbers.length > 0 ? data.GetStatistics.freeVirtualNumbers[0].number : "(None)"}</Typography.Title>} description="Next available number" />
                        </Card>
                    </Col>
                </Row>
            </>}
        </Content>
    </AdminMainUi>;
}