import * as GQL from "../lib/interface_gql";
import { useEffect } from "react";
import { Drawer, Row, Col, Input, Form, Switch, Button, InputNumber } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Errors } from "../lib/util";

export default function AdminPackageDrawer({
    selectedItem,
    setSelectedItem,
    refetch
}: {
    selectedItem: GQL.Package | null;
    setSelectedItem: React.Dispatch<React.SetStateAction<GQL.Package | null>>;
    refetch: (...args: any) => any
}) {
    const [form] = Form.useForm<GQL.PackageUpdate>();
    const [updatePackage, { loading }] = useMutation<Pick<GQL.Mutation, "UpdatePackage">, GQL.MutationUpdatePackageArgs>(gql`
        mutation UpdatePackage($packageId: ID!, $package: PackageUpdate!) {
            UpdatePackage(packageId: $packageId, package: $package)
        }
    `);
    useEffect(() => {
        if (!selectedItem) return;
        form.setFieldsValue({
            ...selectedItem,
            period: Math.trunc(selectedItem.period / (1000 * 60 * 60 * 24))
        });
    });

    return <Drawer
        width={400}
        onClose={() => setSelectedItem(null)}
        open={selectedItem !== null}
        styles={{ body: { paddingBottom: 80 } }}
    >
        <h1>Edit Package</h1>
        <h3>ID: {selectedItem?.id}</h3>
        {selectedItem &&
            <Form form={form} layout="vertical" onFinish={(formData) => {
                updatePackage({
                    variables: {
                        packageId: selectedItem?.id, package: {
                            ...formData,
                            period: formData.period * 24 * 60 * 60 * 1000,
                        }
                    }
                }).then(res => {
                    if (res.data) refetch();
                    setSelectedItem(null);
                }).catch(error => Errors.gql(error));
            }}>
                <Row>
                    <Col>
                        <Form.Item
                            label="Active"
                            name="isActive">
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Package Name"
                            rules={[{ required: true, message: 'This is required' }]}
                        >
                            <Input placeholder="e.g. Gold" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="period"
                            label="Period (days)"
                            rules={[
                                { required: true, message: 'This is required' },
                                { pattern: /^\d{1,3}$/, message: "Must be a 1-3 digit number" }
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="e.g. 14" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="cost"
                            label="Price (Rs.)"
                            rules={[
                                { required: true, message: 'This is required'},
                                { pattern: /^\d{1,5}([.]\d{2})?$/, message: "eg: 1050.34" }
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="e.g. 1005.34" step={0.01}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="tax"
                            label="Tax (Rs.)"
                            rules={[
                                { required: true, message: 'This is required' },
                                { pattern: /^\d{1,5}([.]\d{2})?$/, message: "eg: 1050.34" }
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="e.g. 1005.34" step={0.01} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button loading={loading} type="primary" htmlType="submit">Save</Button>
                    </Col>
                </Row>
            </Form>}
    </Drawer>;
}