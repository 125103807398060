import * as GQL from "../lib/interface_gql";
import { LogoutOutlined, MenuUnfoldOutlined, QuestionCircleOutlined, InboxOutlined, DollarOutlined, HomeOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Errors } from "../lib/util";
import { gql, useMutation } from "@apollo/client";

type MenuItem = Required<MenuProps>["items"][number];

function createNavbarItem(
    label: React.ReactNode,
    key: React.Key,
    icon: React.ReactNode
): MenuItem {
    return { key, icon, label } as MenuItem;
}

export default function UserMainUi(props: {
    selectedKey: string
    children: ReactNode
}) {
    const navigate = useNavigate();
    const [signOut] = useMutation<Pick<GQL.Mutation, "SignOut">, {}>(gql`
        mutation SignOut {
            SignOut
        }
    `);

    return <Layout style={{ height: "100vh" }}>
        <Header style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 24px" }}>
            <img src="/dist/images/ads-connect-logo.png" height={"70%"} alt="Logo" />
            <Menu selectedKeys={[props.selectedKey]} theme="dark" mode="horizontal" overflowedIndicator={<MenuUnfoldOutlined/>} style={{
                    width: "80%",
                    flexGrow: 1
                }} onClick={({ key }) => { navigate(key) }}
                items={[
                    createNavbarItem("Home", "/@me/dashboard", <HomeOutlined />),
                    createNavbarItem("My Purchases", "/@me/purchases", <DollarOutlined />),
                    createNavbarItem("Packages", "/@me/packages", <InboxOutlined />),
                    createNavbarItem("FAQ", "/faq", <QuestionCircleOutlined />)
                ]}
            />
            <Button icon={<LogoutOutlined />} type="link" onClick={() => {
                signOut().then(res => {
                    if (res.data?.SignOut) {
                        sessionStorage.removeItem("user.validTill");
                        sessionStorage.removeItem("user.accessToken");
                        // eslint-disable-next-line no-restricted-globals
                        location.href = "/";
                    }
                }).catch(error => Errors.gql(error))
            }}>Logout</Button>
        </Header>
        <Layout style={{ padding: 24 }}>
            {props.children}
        </Layout>
    </Layout>
}