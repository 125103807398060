import { Card, Col } from "antd";
import UserMainUi from "../components/UserMainUi";

export default function UserFaq() {
    return <UserMainUi selectedKey="/faq">
        <Col md={{ push: 6, span: 12 }}>
            <Card style={{ marginBottom: 16 }} title="Q1: What is Ads Connect?">
                <b>A1</b>: Ads Connect is a service designed to enhance your privacy while running advertisements. It provides users with temporary phone numbers to display in their ads, ensuring that personal contact numbers remain confidential.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q2: How does Ads Connect work, and how can I receive calls from my leads?">
                <b>A2</b>: Ads Connect assigns you a temporary phone number that you can use in your advertisements. To receive calls from your leads, you'll need to install the 3CX app on your smartphone. You can find the download links for Android and iOS on our website. Incoming calls will be received through the 3CX app, ensuring your privacy is protected.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q3: What are the benefits of using Ads Connect?">
                <b>A3</b>: By using Ads Connect, you can:
                <ul>
                    <li>Safeguard your personal contact information.</li>
                    <li>Maintain control over your temporary number.</li>
                    <li>Improve privacy and security.</li>
                    <li>Boost your ad campaign's credibility.</li>
                </ul>
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q4: How do I complete the payment process for my Ads Connect package, and what happens after payment?">
                <b>A4</b>: To get started with Ads Connect, simply sign up on our website and choose a package that suits your needs. Once you've selected a package, you will be directed to the payment process. Follow the bank details provided on our website to transfer the necessary
                amount. After making the payment, please upload the receipt through your Ads Connect account. Our admin team will review and validate the payment, usually within 24 hours, to ensure a smooth activation of your Ads Connect package.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q5: How long does it take for admin approval?">
                <b>A5</b>: Admin approval typically takes up to 24 hours after you've procured a package.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q6: What happens if I deactivate my Ads Connect package, and can I reactivate it later?">
                <b>A6</b>: If you deactivate your Ads Connect package, your access to the service will be suspended. To resume using Ads Connect, you will need to purchase a new package directly from our website. Please note that your account and data will be retained, making it a convenient process to restart with a new package whenever you decide to return.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q7: How can I contact Ads Connect support?">
                <b>A7</b>: You can reach our support team through the contact information provided on our website. We're here to assist you with any questions or issues you may have.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q8: Is Ads Connect available in multiple packages?">
                <b>A8</b>: No, Ads Connect currently offers a single package option to cater to your needs. You can explore and choose this package on our website.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q9: Is my personal information safe with Ads Connect?">
                <b>A9</b>: Yes, your personal information is safe with Ads Connect. We do not share your data with any third parties for marketing or commercial purposes. Your information is used solely for the provision of the Ads Connect service. However, in compliance with legal
                requirements, we may be obligated to share data with law enforcement institutes when necessary to ensure the safety and security of our users and the public. Your privacy and data security remain our top priorities.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q10: Can I make outbound calls from Ads Connect?">
                <b>A10</b>: No, Ads Connect is designed primarily for inbound communication. You cannot make outbound calls using Ads Connect. It focuses on protecting your privacy while receiving calls from potential customers.
            </Card>
            <Card style={{ marginBottom: 16 }} title="Q11: Can I send or receive SMS from Ads Connect?">
                <b>A10</b>: No, you cannot send or receive SMS (text messages) using Ads Connect. Ads Connect is primarily designed for inbound call management and does not support SMS functionality.
            </Card>
        </Col>
    </UserMainUi>;
}