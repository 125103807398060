import { useEffect } from "react";
import * as GQL from "../lib/interface_gql";
import { Drawer, Row, Col, Input, Form, Switch, Button } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Errors } from "../lib/util";

export default function AdminUserDrawer({
    selectedItem,
    setSelectedItem,
    refetch
}: {
    selectedItem: GQL.User | null;
    setSelectedItem: React.Dispatch<React.SetStateAction<GQL.User | null>>;
    refetch: (...args: any) => any
}) {
    const [form] = Form.useForm<GQL.UserUpdate>();
    const [updateUser, { loading }] = useMutation<Pick<GQL.Mutation, "UpdateUser">, GQL.MutationUpdateUserArgs>(gql`
        mutation UpdateUser($userId: ID!, $user: UserUpdate!) {
            UpdateUser(userId: $userId, user: $user)
        }
    `);
    useEffect(() => {
        if (!selectedItem) return;
        form.setFieldsValue(selectedItem);
    });

    return <Drawer
        width={400}
        onClose={() => setSelectedItem(null)}
        open={selectedItem !== null}
        styles={{ body: { paddingBottom: 80 } }}
    >
        <h1>Edit User</h1>
        <h3>ID: {selectedItem?.id}</h3>
        {selectedItem &&
            <Form form={form} layout="vertical" onFinish={(formData) => {
                updateUser({
                    variables: { userId: selectedItem?.id, user: formData }
                }).then(res => {
                    if (res.data) refetch();
                    setSelectedItem(null);
                }).catch(error => Errors.gql(error));
            }}>
                <Row>
                    <Col>
                        <Form.Item
                            label="Active"
                            name="isActive">
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[{ required: true, message: 'This is required' }]}
                        >
                            <Input placeholder="e.g. John" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[{ required: true, message: 'This is required' }]}
                        >
                            <Input placeholder="e.g. John" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button loading={loading} type="primary" htmlType="submit">Save</Button>
                    </Col>
                </Row>
            </Form>}
    </Drawer>;
}