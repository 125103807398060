import * as GQL from "../lib/interface_gql";
import { LockOutlined, MobileOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Button, Card, Flex, Form, Input, Layout, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Errors } from "../lib/util";

export default function AdminLogin() {
    const navigate = useNavigate();
    const [signIn, { loading, data }] = useMutation<Pick<GQL.Mutation, "SignIn">, GQL.MutationSignInArgs>(gql`
        mutation SignIn($mobile: String!, $password: String!) {
            SignIn(mobile: $mobile, password: $password) {
                accessToken validTill
            }
        }
    `);
    const [form] = Form.useForm<GQL.MutationSignInArgs>();

    useEffect(() => { if (data) navigate("/admins/dashboard"); });

    return (
        <Layout style={{ height: "100vh" }}>
            <Flex style={{height: "100%"}} justify="center" align="center">
                <Card
                    bodyStyle={{ padding: "50px" }}
                    style={{
                        width: "400px",
                        margin: "100px auto",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    <Typography.Title
                        level={2}
                        style={{ marginBottom: "30px", textAlign: "center" }}
                    >
                        Welcome! Admin
                    </Typography.Title>
                    <Form form={form} onFinish={values => {
                        signIn({
                            variables: values
                        }).then(res => {
                            if (res.data) {
                                sessionStorage.setItem("admin.accessToken", res.data.SignIn.accessToken);
                                sessionStorage.setItem("admin.validTill", res.data.SignIn.validTill.toString());
                            };
                        }).catch(error => Errors.gql(error));
                    }}>
                        <Form.Item
                            name="mobile"
                            rules={[
                                { required: true, message: "This is required" },
                                { pattern: /0\d{9}/, message: "Must contain 10 digits. Starts with 0" }
                            ]}
                        >
                            <Input prefix={<MobileOutlined />} placeholder="eg: 071XXXXXXX" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: "This is required" },
                                { max: 20, message: "Must not exceed 20 characters" }
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{ width: "100%" }}
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Flex>
        </Layout>
    );
};
