import * as GQL from "../lib/interface_gql";
import { useEffect } from "react";
import { Drawer, Row, Col, Input, Form, Switch, Button } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Errors } from "../lib/util";

export default function AdminVirtualNumberDrawer({
    selectedItem,
    setSelectedItem,
    refetch
}: {
    selectedItem: GQL.VirtualNumber | null;
    setSelectedItem: React.Dispatch<React.SetStateAction<GQL.VirtualNumber | null>>;
    refetch: (...args: any) => any
}) {
    const [form] = Form.useForm<GQL.VirtualNumberUpdate>();
    const [updateVirtualNumber, { loading }] = useMutation<Pick<GQL.Mutation, "UpdateVirtualNumber">, GQL.MutationUpdateVirtualNumberArgs>(gql`
        mutation UpdateVirtualNumber($virtualNumberId: ID!, $virtualNumber: VirtualNumberUpdate!) {
            UpdateVirtualNumber(virtualNumberId: $virtualNumberId, virtualNumber: $virtualNumber)
        }
    `);
    useEffect(() => {
        if (!selectedItem) return;
        form.setFieldsValue(selectedItem);
    });

    return <Drawer
        width={400}
        onClose={() => setSelectedItem(null)}
        open={selectedItem !== null}
        styles={{ body: { paddingBottom: 80 } }}
    >
        <h1>Edit Temporary Number</h1>
        <h3>ID: {selectedItem?.id}</h3>
        {selectedItem &&
            <Form form={form} layout="vertical" onFinish={(formData) => {
                updateVirtualNumber({
                    variables: { virtualNumberId: selectedItem?.id, virtualNumber: formData }
                }).then(res => {
                    if (res.data) refetch();
                    setSelectedItem(null);
                }).catch(error => Errors.gql(error));
            }}>
                <Row>
                    <Col>
                        <Form.Item
                            label="Active"
                            name="isActive">
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="number"
                            label="Number"
                            rules={[
                                { required: true, message: 'This is required' },
                                { pattern: /^0\d{9}$/, message: 'Must be 10 digits and must start with 0' }
                            ]}
                        >
                            <Input disabled placeholder="e.g. 0XXXXXXXXX" />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="provider"
                            label="Provider"
                        >
                            <Input placeholder="e.g. Mobitel" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button loading={loading} type="primary" htmlType="submit">Save</Button>
                    </Col>
                </Row>
            </Form>}
    </Drawer>;
}