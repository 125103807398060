import * as GQL from "../lib/interface_gql";
import * as Config from "../../config";
import dayjs from 'dayjs';
import React from "react";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Drawer, Row, Col, Form, Button, DatePicker, Radio, Space, Typography, Popconfirm } from "antd";
import { gql, useMutation } from "@apollo/client";
import { Errors } from "../lib/util";

export default function AdminPurchaseDrawer({
    selectedItem,
    setSelectedItem,
    refetch
}: {
    selectedItem: GQL.Purchase | null;
    setSelectedItem: React.Dispatch<React.SetStateAction<GQL.Purchase | null>>;
    refetch: (...args: any) => any
}) {
    const [form] = Form.useForm<{
        state: GQL.PurchaseState,
        endsAt: dayjs.Dayjs
    }>();
    const [updatePurchase, { loading: ld0 }] = useMutation<Pick<GQL.Mutation, "UpdatePurchase">, GQL.MutationUpdatePurchaseArgs>(gql`
        mutation UpdatePurchase($purchaseId: ID!, $purchase: PurchaseUpdate!) {
            UpdatePurchase(purchaseId: $purchaseId, purchase: $purchase)
        }
    `);
    const [markPurchaseForArchival, { loading: ld1 }] = useMutation<Pick<GQL.Mutation, "MarkPurchaseForArchival">, GQL.MutationMarkPurchaseForArchivalArgs>(gql`
        mutation MarkPurchaseForArchival($purchaseId: ID!) {
            MarkPurchaseForArchival(purchaseId: $purchaseId)
        }
    `);
    const [receiptUrl, setReceiptUrl] = useState<string>("");

    useEffect(() => {
        if (!selectedItem) return;

        form.setFieldsValue({
            ...selectedItem,
            endsAt: dayjs(selectedItem.endsAt)
        });

        fetch(`${Config.URL_API_REST}/purchases/${selectedItem.id}/receipt`, {
            method: "GET",
            headers: {
                "Authorization": sessionStorage.getItem("admin.accessToken")!
            }
        }).then(res => res.blob()).then(blob => {
            //Remove previously created object urls if any
            URL.revokeObjectURL(receiptUrl);
            setReceiptUrl(URL.createObjectURL(blob));
        }).catch(error => console.error(error));
    }, [selectedItem]);

    return <Drawer
        width={400}
        onClose={() => setSelectedItem(null)}
        open={selectedItem !== null}
        styles={{ body: { paddingBottom: 80 } }}
    >
        <h1>Edit Purchase</h1>
        <h3>ID: {selectedItem?.id}</h3>
        {selectedItem && <>
            <Space direction="vertical">
                <Typography.Paragraph>Receipt</Typography.Paragraph>
                <Button target="_blank" href={receiptUrl} icon={<EyeOutlined />}>Preview</Button>
            </Space>

            <Form style={{ marginTop: 24 }} form={form} layout="vertical" onFinish={(formData) => {
                updatePurchase({
                    variables: {
                        purchaseId: selectedItem?.id, purchase: {
                            state: formData.state,
                            endsAt: formData.endsAt.toDate().getTime()
                        }
                    }
                }).then(res => {
                    if (res.data) refetch();
                    setSelectedItem(null);
                }).catch(error => Errors.gql(error));
            }}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="State"
                            name="state">
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                options={[
                                    { label: "Initiated", value: GQL.PurchaseState.INITIATED, disabled: true },
                                    { label: "Suspended", value: GQL.PurchaseState.SUSPENDED },
                                    { label: "Active", value: GQL.PurchaseState.ACTIVE }
                                ]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Valid till"
                            name="endsAt"
                        >
                            <DatePicker disabledDate={(current) => {
                                const now = new Date();
                                now.setHours(24);
                                return current.toDate().getTime() <= now.getTime();
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Space.Compact>
                        <Button loading={ld0} type="primary" htmlType="submit">Save</Button>
                        <Popconfirm title="This action cannot be reversed. Are you sure?" description="This action is equivalent to marking a purchase as expired" okText="Yes" cancelText="No" onConfirm={() => {
                            markPurchaseForArchival({ variables: { purchaseId: selectedItem.id } })
                                .then(res => {
                                    if (res.data) refetch();
                                    setSelectedItem(null);
                                }).catch(error => Errors.gql(error));
                        }}>
                            <Button icon={<DeleteOutlined />} loading={ld1} type="primary" danger>Archive</Button>
                        </Popconfirm>
                    </Space.Compact>
                </Row>
            </Form>
        </>}
    </Drawer>;
}