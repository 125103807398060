import * as GQL from "../lib/interface_gql";
import * as Icon from "@ant-design/icons";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "@apollo/client";
import { Alert, Button, Card, Flex, Form, Input, InputRef, Layout, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Errors } from "../lib/util";

export default function UserPassword() {
    const navigate = useNavigate();
    const [generateOtp, { loading: ld0, data: dt0 }] = useMutation<Pick<GQL.Mutation, "GenerateOtp">, GQL.MutationGenerateOtpArgs>(gql`
        mutation GenerateOtp($for: OtpFor!, $mobile: String!) {
            GenerateOtp(for: $for, mobile: $mobile)
        }
    `);
    const [resetPassword, { loading: ld1, data: dt1 }] = useMutation<Pick<GQL.Mutation, "ResetPassword">, GQL.MutationResetPasswordArgs>(gql`
        mutation ResetPassword($mobile: String!, $otp: String!, $password: String!) {
            ResetPassword(mobile: $mobile, otp: $otp, password: $password) { accessToken validTill }
        }
    `);
    const [form] = Form.useForm<GQL.MutationResetPasswordArgs & {
        otp0: string, otp1: string, otp2: string, otp3: string, otp4: string, otp5: string
    }>();
    const inputOtpRefs = useRef([
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
        React.createRef<InputRef>(),
    ]);
    const [openOtpModal, setOpenOtpModal] = useState(false);

    useEffect(() => { if (dt1) navigate("/@me/dashboard"); });

    return <Layout style={{ height: "100vh" }}>
        <Card bodyStyle={{ padding: "50px" }} style={{
            width: "400px",
            margin: "100px auto",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
        }} >
            <Typography.Title
                level={2}
                style={{ marginBottom: "30px", textAlign: "center" }}
            >Reset Password</Typography.Title>
            <Form form={form} onFinish={formData => {
                resetPassword({
                    variables: {
                        mobile: formData.mobile,
                        otp: formData.otp0 + formData.otp1 + formData.otp2 + formData.otp3 + formData.otp4 + formData.otp5,
                        password: formData.password
                    }
                }).then(res => {
                    if (res.data) {
                        sessionStorage.setItem("user.accessToken", res.data.ResetPassword.accessToken);
                        sessionStorage.setItem("user.validTill", res.data.ResetPassword.validTill.toString());
                    }
                }).catch(error => Errors.gql(error));
            }} layout="vertical">
                <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                        { required: true, message: "This is required" },
                        { max: 20, message: "Must not exceed 20 characters" }
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                        { required: true, message: "This is required" },
                        { pattern: /^0\d{9}$/, message: "Mobile number must be 10 digits. Starts with 0" },
                    ]}
                >
                    <Space.Compact style={{ width: "100%" }}>
                        <Input
                            prefix={<Icon.MobileOutlined />}
                            placeholder="e.g. 077XXXXXXX"
                        />

                        <Button disabled={openOtpModal} loading={ld0} type="primary" onClick={async () => {
                            let formData;
                            try {
                                formData = await form.validateFields();
                            } catch (error) {
                                console.log("Form validation error", error);
                                return;
                            }

                            await generateOtp({
                                variables: {
                                    for: GQL.OtpFor.MOBILE_ACCOUNT_VALIDATION,
                                    mobile: formData.mobile
                                }
                            }).then(res => {
                                if (res.data?.GenerateOtp) {
                                    setOpenOtpModal(true);
                                    setTimeout(() => setOpenOtpModal(false), 120000);
                                }
                            }).catch(error => Errors.gql(error));
                        }}>Request OTP</Button>
                    </Space.Compact>
                </Form.Item>

                {openOtpModal && <>
                    <Alert message={<p>We've sent an OTP to your mobile and will expire in 2 minutes</p>} type="success" style={{ marginBottom: 24 }} />
                    <Flex justify="space-between">{Array.from({ length: 6 }, (_, index) => (<Form.Item
                        key={index}
                        name={`otp${index}`}
                        rules={[
                            { required: true, message: "" },
                            { pattern: /\d{1}/, message: "" }
                        ]}>
                        <Input onKeyUp={() => {
                            const nextInputRef = inputOtpRefs.current[index + 1]?.current;
                            if (nextInputRef) {
                                nextInputRef.focus()
                                nextInputRef.input!.value = ""
                            }
                        }}
                            ref={inputOtpRefs.current[index]}

                            style={{ width: "40px", height: "40px" }}
                            maxLength={1}
                        />
                    </Form.Item>))}</Flex>
                </>}

                {dt0?.GenerateOtp && openOtpModal && <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"

                        loading={ld1}
                        style={{ width: "100%" }}
                    >Submit</Button>
                </Form.Item>}
            </Form>
        </Card>
    </Layout>;
};
