export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: number; output: number; }
};

export type Account3cx = {
  __typename?: 'Account3cx';
  customerId?: Maybe<Scalars['Int']['output']>;
  extensionId?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  hasProvisionedNumber: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  provisionLink?: Maybe<Scalars['String']['output']>;
};

export enum GenieState {
  AUTHORIZED = 'AUTHORIZED',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  QR_CODE_GENERATED = 'QR_CODE_GENERATED',
  REFUNDED = 'REFUNDED',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  VOIDED = 'VOIDED'
}

export type GenieTransaction = {
  __typename?: 'GenieTransaction';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  state: GenieState;
  url: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateUser: Token;
  GenerateOtp: Scalars['Boolean']['output'];
  InitPaymentByCard: GenieTransaction;
  InitPaymentByReceipt: Scalars['Boolean']['output'];
  ReservePurchase: Purchase;
  ResetPassword: Token;
  SignIn: Token;
  SignOut: Scalars['Boolean']['output'];
  SubscribeAlert: Scalars['Boolean']['output'];
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationGenerateOtpArgs = {
  for: OtpFor;
  mobile: Scalars['String']['input'];
};


export type MutationReservePurchaseArgs = {
  packageId: Scalars['ID']['input'];
  paymentMethod: PaymentMethod;
};


export type MutationResetPasswordArgs = {
  mobile: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  mobile: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSubscribeAlertArgs = {
  onFreeNumber: Scalars['Boolean']['input'];
};

export enum OtpFor {
  MOBILE_ACCOUNT_VALIDATION = 'MOBILE_ACCOUNT_VALIDATION',
  MOBILE_VALIDATION = 'MOBILE_VALIDATION'
}

export type Package = {
  __typename?: 'Package';
  cost: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  period: Scalars['Date']['output'];
  tax: Scalars['Float']['output'];
};

export enum PaymentMethod {
  CARD = 'CARD',
  DEPOSIT = 'DEPOSIT',
  LANKA_QR = 'LANKA_QR'
}

export type Purchase = {
  __typename?: 'Purchase';
  endsAt: Scalars['Date']['output'];
  genieTransactionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initAt: Scalars['Date']['output'];
  package: Package;
  payment: Scalars['Float']['output'];
  paymentMethod: PaymentMethod;
  paymentTax: Scalars['Float']['output'];
  state: PurchaseState;
  virtualNumber: VirtualNumber;
};

export enum PurchaseState {
  ACTIVE = 'ACTIVE',
  INITIATED = 'INITIATED',
  MODIFYING = 'MODIFYING',
  RESERVED = 'RESERVED',
  SUSPENDED = 'SUSPENDED'
}

export type PurchaseStats = {
  __typename?: 'PurchaseStats';
  activePurchase?: Maybe<Purchase>;
  hasPurchases: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  GetMe: User;
  GetMyPurchaseStats: PurchaseStats;
  GetPackages: Array<Package>;
};


export type QueryGetPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  validTill: Scalars['Date']['output'];
};

export type User = {
  __typename?: 'User';
  account3cx: Account3cx;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
  purchases: Array<Purchase>;
};


export type UserPurchasesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type UserInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type VirtualNumber = {
  __typename?: 'VirtualNumber';
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  provider: Scalars['String']['output'];
};
